body {
  background-color:#FFF1CF;
}

.wrapper {
  animation-duration: 2s;
  animation-delay: 0.3s;
}

.container-fluid {
  padding-left:5vw;
  padding-right:5vw;
}

.container-xl {
  padding-left:1vw;
  padding-right:1vw;
}

.container-intro {
  margin-top:9.5rem !important;
}

/* navbar top */

.navbar-top .navbar {
  padding-top:2rem;
  padding-left:0;
  padding-right:0;
  /* if fixed-top padding-bottom:4.5rem; */
}

.navbar-top .navbar-expand-sm .navbar-nav .nav-link {
  padding-left:0;
  padding-right:0;
}

.navbar-top .navbar {
}

a, button, [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: url(img/cursor-link.png), auto;
}


.navbar-brand {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
}

a.navbar-brand {
  padding-top:2rem;
}

#headerLogo {
  height:110px;
  transition:0.2s;
}

.navbar-brand img {
  transition:0.2s;
}

/* modal */

#modal-impressum .modal-content {
    background-color: #FFF1CF;
    border: 1px solid #D81A11;
    border-radius: .3rem;
    outline: 0;
  }

#modal-impressum .close {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: #D81A11;
    opacity:1;
  }

.modal-header {
    border-bottom:0px;
  }

button.close:focus {
      outline: none;
  }

  .modal-body {
    text-align:justify;
    column-count:2;
    column-gap:1rem;
  }

#modal-impressum p {
  margin-bottom:2rem;
}

#modal-impressum h3 {
  margin-bottom:0.75rem;
}

/* homepage */


.gif-img {
  display: flex;
  align-items:center;
}

/* events */


.event-box {
  border:none;
  border-radius: 2px;
  height:100%;
}


.event-box p {
  margin-bottom:0.5rem;
  }

.events .event-box a {
  border-bottom: 1px solid #D81A11 !important;
  text-decoration:none;
}

.event-box h2 {
  margin-bottom:0;
}

.event-box h3 {
  border-bottom: 2px solid #D81A11;
  margin-bottom:1.2rem;
  padding-bottom: 0.5rem;
}

/* stories */

#stories {
  display: flex;
  align-items: self-start;
}

.post-active img {
  margin-bottom: 1rem;
}

.post-active {
  opacity:1;
}

.post-active-content h3 {
  border-bottom:0 !important;
}

.post-active-content a {
  border-bottom:1px solid #D81A11 !important;
}

.post-active-content h3 a {
  border-bottom:0 !important;
}

.post-active-content ul {
  list-style: none;
}

.post-active-content ul li::before {
  content: "\2022";
  color: #D81A11;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  vertical-align: top;
}

.post-active-content li p {
    display: inline-block;
}



.img-post {
  width: 100%;
  height: auto;
}

.post-thumbnail {
  max-height: 250px;
  width:100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.stories-post h3 {
  border-bottom: 1px solid #D81A11;
  padding-bottom: 0.4rem;
  line-height: 1.5rem;
  margin-bottom: 0.4rem;
}

.read-more button, .read-less button {
  border: none;
  background: transparent;
  color: #D81A11;
}

.read-more button:focus, .read-less button:focus {
    outline: none;
}


.read-more, .read-less {
  width: 50%;
  float: right;
  text-align: right;
}

.post-date {
  width:50%;
  float:left;
}

/* video */

.banner-video video {
  width:100%;
}



/* footer */

.navbar-footer .navbar {
  padding-bottom:0;
}

.navbar-footer .navbar-expand-sm .navbar-nav .nav-link {
  padding-bottom:0;
}

.site-credits {
  text-align:right;
}


.site-credits h3 {
  font-size:0.7rem;
  margin-bottom:1rem;
  line-height: 0.8rem;
  padding-right: 3rem;
}

.site-credits h3 a {
  color:#D81A11;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 0px;
  transition: background-size 1.2s;
}

.site-credits h3 a:hover {
  background-size: 100% 1px;
}


/* imgs */

.img-full, .video-full {
  width:100%;
  height: auto;
}

.video {
  isolation: isolate;
}

.image, .video {
    max-height: 75vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.image:after,
.image:before, .video:after, .video:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    pointer-events: none;
    z-index: 100;
}

.image:before {
  background: #CCC;
  mix-blend-mode: color;
}

.video:before {

}

.image:after, .video:after {
    background: #d4d0bc;
    mix-blend-mode: multiply;
    transition: opacity .5s ease;
}


/* font styles */

a {
  color:#D81A11;
}

a:hover {
  color:#D81A11;
  text-decoration:none;
}

h1 {
  font-family: 'Windsor';
  font-weight:bold;
  font-size:2.5rem;
  color: #D81A11;
  margin-bottom:0;
}

h3, h2 {
  font-family: 'ITCKabelMedium';
  font-size:1.4rem;
  letter-spacing: 0.03rem;
  line-height: 1.9rem;
  color: #D81A11;
  margin-bottom:2rem;
}

h3 a {
  color:#D81A11;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: background-size .5s;
}

h3 a:hover {
  background-size: 0% 2px;
}

p {
  font-family: 'Bkkman';
  font-size:1rem;
  color: #D81A11;
  line-height: 1.4rem;
  margin-bottom:0;
  letter-spacing: -0.02rem;
}

.metadata {
  font-family: 'ITCKabelMedium';
  font-size:0.9rem;
  letter-spacing: 0.02rem;
  color: #D81A11;
}

.metadata button {
  padding-right:0;
}

.title-menu {
  font-family: 'Windsor';
  font-weight: normal;
  text-transform: uppercase;
  font-size:1.2rem;
  color: #D81A11 !important;
}


.post-active-content p {
  margin-bottom:1rem;
}

.post-active-content h4 {
  font-family: 'Bkkman';
  margin-bottom:1rem;
  font-size:1rem;
  color: #D81A11;
}


/* font files */

@font-face {
    font-family: 'ITCKabelMedium';
    src: url(./fonts/ITCKabelStd-Medium.woff),
        url(./fonts/ITCKabelStd-Medium.woff2);
}

@font-face {
    font-family: 'Bkkman';
    src: url(./fonts/Bookman-OldStyle-Regular.TTF);
}


@font-face {
    font-family: 'Windsor';
    src: url(./fonts/WindsorD-Reg.woff),
    url(./fonts/WindsorD-Reg.woff2);
    font-weight: normal;
}

@font-face {
    font-family: 'Windsor';
    src: url(./fonts/WindsorD-Bol.woff),
    url(./fonts/WindsorD-Bol.woff2);
    font-weight: bold;
}
