/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

 }

@media (max-width: 577px) {


  .image {
    height: 30vh;
overflow: hidden;
display: flex;
align-items:normal;
/* object-fit: cover; */
  }

  .img-full, .banner-video video {
    object-fit: cover;
    width: 100%;
  }



  h1 {
    font-size:2.2rem;
    margin-bottom:0.4rem;
  }

  h3, h2 {
    font-size:1.4rem;
    line-height: 1.7rem;
  }


  .metadata {
    line-height: 0.9rem;
  }

  #headerLogo {
    height: 80px;
  }

  .navbar-top .navbar {
    padding-top: 4rem;
  }


  .event-box {
    border:none;
    height:auto;
  }

  .event-box p {
    line-height: 1.1rem;
  }


  .event-box h3 {
    line-height: 1rem;
    margin-bottom:0.5rem;
  }

  .post-thumbnail {
    max-height:160px;
  }

  .metadata {
      font-size: 0.7rem;
      line-height: 0.85rem;
      letter-spacing: 0;
    }

  .site-credits {
      text-align:center;
    }

  .site-credits h3, .footer-mobile h1 {
      padding-right: 0;
      font-size:0.6rem;
      margin-bottom:0;
    }

    .footer-mobile {
      text-align:center;
      display: block;
      text-transform:uppercase;
    }

  .footer-mobile h1 {
    font-weight: normal;
    font-size:1rem;
  }

  .events h1, .catering h1, .stories h1, .contact h1 {
    margin-bottom:0;
  }

  .catering h3, .stories h3 {
    margin-bottom:1rem;
  }

  .stories-post h3 {
    font-size:1.1rem;
    letter-spacing: 0rem;
    line-height: 1.2rem;
    margin-bottom: 0.5rem;
    padding-bottom:0.3rem;
  }



  p {
    font-size:0.9rem;
    line-height: 1.1rem;
  }


}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 578px) {
  .footer-mobile {display: none;}
 }

@media (min-width: 578px) and (max-width: 769px) {

#headerLogo {
    height:80px;
  }

  a.navbar-brand {
    padding-top: 1rem;
}

.navbar-top .navbar {
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
}

  .modal-body {
    column-count:1;
    column-gap:2rem;
  }

  h3 {
    margin-bottom:1rem;
  }

  .title-menu {
    font-size:1.15rem;
  }

  .metadata {
    font-size: 0.72rem;
    letter-spacing: 0;
    line-height: 0.9rem;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }
